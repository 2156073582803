import React, { Component } from 'react';
import { withRouter } from 'react-router';
import usersService from './UsersService';
import { Button } from 'reactstrap';

class UsersApprovePlain extends Component {
	constructor(props) {
		super(props);
		this.state = { users: [], loading: true };

		const { match } = this.props;
		this.userId = match.params.userId;
	}

	componentDidMount() {

	}

	handleClickOk = () => {
		const { history } = this.props;

		(async () => {
			await usersService.ApproveUser(this.userId);
			history.push('/users');
		})();

	}

	handleClickCancel = () => {
		const { history } = this.props;

		history.push('/users');
	}

	render() {
		return (
			<center>
			<div>
				<h2>请确认是否要审核该用户?</h2>

				<button onClick={this.handleClickOk}>Yes</button>&nbsp;
				<button onClick={this.handleClickCancel}>No</button>
				</div></center>
		);
	}
}

export const UsersApprove = withRouter(UsersApprovePlain);
