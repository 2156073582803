import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined, DownOutlined, SettingOutlined, TeamOutlined, KeyOutlined } from '@ant-design/icons';
import { Layout, Avatar, Popover, Dropdown, Modal, Input, message } from 'antd';
import '../../style/header.less';
import i18n from 'i18next';
// import usersService from '../../components/users/UsersService';
import fetch from '../../fetch/axios';
import moment from 'moment';

const { Header } = Layout;

class HeaderBar extends React.Component {
  state = {
    language: 'en',
    showLanguage: "En",
    promptsLibOpen: false,

    isChangePwdOpen: false,
    pwdObj: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    pwdObjError: {
      oldPassword: false,
      password: false,
      confirmPassword: false,
      confirmPasswordDiff: false,
    },
  }
    componentDidMount() {
        i18n.changeLanguage('zh-cn');
        moment.locale('zh-cn');
        this.setState({ language: 'zh-cn', showLanguage: "中文" })
  }

  componentDidUpdate() {
    if (this.props.reducer.chatInfo.promptsLibOpen !== this.state.promptsLibOpen) {
      this.setState({ promptsLibOpen: this.props.reducer.chatInfo.promptsLibOpen })
    }
  }

  changePath(url) {
    this.props.history.push(url);
  }

  changeLanguage(lan) {
    if (lan === 'en') {
      i18n.changeLanguage('zh-cn');
      moment.locale('zh-cn');
      this.setState({ language: 'zh-cn', showLanguage: "中文" })
    } else if (lan === 'zh-cn') {
      i18n.changeLanguage('en');
      moment.locale('en');
      this.setState({ language: 'en', showLanguage: "En" })
    }
  }

  openLibrary() {
    // console.log(this.state.promptsLibOpen, this.state.promptsLibOpen ? false : true)
    // console.log(this.props.reducer.chatInfo)

    if (this.props.history.location.pathname !== '/chat-official') {
      // 路由不在聊天页面时，跳转到聊天页面，并打开library
      this.props.history.push('/chat-official');
      this.props.setChatInfo({
        promptsLibOpen: true,
      });
    } else {
      this.props.setChatInfo({
        promptsLibOpen: this.state.promptsLibOpen ? false : true,
      });
    }
  }

  changePwd(e, key) {
    const { pwdObj, pwdObjError } = this.state;
    pwdObj[key] = e;
    pwdObjError[key] = !e;
    pwdObjError.confirmPasswordDiff = !!pwdObj.password && !!pwdObj.confirmPassword && (pwdObj.password !== pwdObj.confirmPassword);

    this.setState({ pwdObj, pwdObjError });
  }
  async handleChangePwd() {
    const { pwdObj, pwdObjError } = this.state;
    let errNum = 0;
    // 数据验证
    if (!pwdObj.oldPassword) {
      pwdObjError.oldPassword = true;
      errNum++;
    }
    if (!pwdObj.password) {
      pwdObjError.password = true;
      errNum++;
    }
    if (!pwdObj.confirmPassword) {
      pwdObjError.confirmPassword = true;
      errNum++;
    }
    if (pwdObj.password !== pwdObj.confirmPassword) {
      pwdObjError.confirmPasswordDiff = true;
      errNum++;
    }
    this.setState({ pwdObjError });
    if (errNum > 0) {
      return;
    }

    let obj = {
      OldPassword: pwdObj.oldPassword,
      NewPassword: pwdObj.password,
    }

    // 保存
    fetch.post('/api/Personal/update-password', obj).then(res => {
      if (res.success) {
        this.setState({ isChangePwdOpen: false, pwdObj: { password: "", confirmPassword: "" }, pwdObjError: {} });
        message.success(this.props.t('users.Change Password Success'));
      } else {
        message.error(res.message);
      }
    })
  }

  render() {
    const { collapsed, smallScreen, moveToOpen, user, t, headerTitle, rights } = this.props;
    const { pwdObj, pwdObjError } = this.state;
    const content = (
      <div className='header_dropdown'>
        <div className='hd_header'>
          <div className='dropdown_btn'>
            <Avatar className='person_box'>{user.shortName}</Avatar>
            <div className='person_name'>{user.name}</div>
          </div>
        </div>
        <div className='hd_line'></div>
        <div className='hd_body'>
          <div className='dropdown_btn' onClick={() => { this.setState({isChangePwdOpen: true}) }}><KeyOutlined />{t('users.Change Password')}</div>
          {!!rights.user && <>
            <div className='dropdown_btn' onClick={() => { this.changePath('/system/user') }}><TeamOutlined />{t('header.User Management')}</div>
            {/* <div className='dropdown_btn' onClick={()=>{ this.changePath('/profile') }}><UserOutlined />{t('header.My Profile')}</div>
            <div className='dropdown_btn' onClick={()=>{ this.changePath('/pricing') }}><MoneyCollectOutlined />{t('header.Pricing')}</div>
            <div className='dropdown_btn' onClick={()=>{ this.changePath('/billing') }}><CreditCardOutlined />{t('header.Billing')}</div> */}
          </>}
        </div>
        <div className='hd_line'></div>
        <div className='hd_bottom'>
          <div className='dropdown_btn' onClick={() => {
            // 退出登录
            // this.props.userLogOut()
            this.props.history.push('/authentication/logout');
          }}><LogoutOutlined />{t('header.Log Out')}</div>
        </div>
      </div>
    );
    const items = [
      {
        label: <div onClick={() => { this.changeLanguage('zh-cn') }}>English</div>,
        key: 'En',
      },
      {
        label: <div onClick={() => { this.changeLanguage('en') }}>中文</div>,
        key: 'zh-cn',
      },
    ];
    return (
      <div className="header_bar">
        <Header>
          <div className='header_lt'>
            {smallScreen ? React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'nav_close',
              onClick: () => {
                if (!collapsed && moveToOpen) {
                  this.props.setNavData({ collapsed: false, moveToOpen: false })
                  return
                }
                this.props.setNavData({ collapsed: !collapsed, moveToOpen: !collapsed })
              },
            }) : null}
            {/* <Button className='header_prompt_btn' size='middle' onClick={() => { this.openLibrary() }} >{t('header.Prompts library')}</Button> */}
            <div className='header_title'>{
              headerTitle.map((item, id) => {
                return <div className={`title_item title_${id}`} key={id}>
                  {id>0&&">"} 
                  <div className='title_text' onClick={()=>{ item.path&&this.props.history.push(item.path) }}>{t(item.title, item.obj)}</div>
                </div>
              })
            }</div>
          </div>
          <div className='header_gt'>
            <Dropdown
              trigger={['click']}
              placement="bottom"
              menu={{
                items,
              }}
            >
              <div className='header_language'>
                {this.state.showLanguage}
                <DownOutlined />
              </div>
            </Dropdown>
            {user?.role?.includes("administrator") &&
              <SettingOutlined className='header_language' onClick={() => { this.changePath("/general/azure-setting") }} />}
            <Popover content={content} trigger="click" arrow={false} placement="bottomRight">
              <Avatar className='person_box' size={34}>{user.shortName}</Avatar>
            </Popover>
          </div>
        </Header>
        <Modal centered title={t('users.Change Password')} open={this.state.isChangePwdOpen} onOk={() => { this.handleChangePwd() }} onCancel={() => { this.setState({ isChangePwdOpen: false }) }}>
          <div className='hd_form'>
            <div className='ul_form_title'>{t('users.oldPassword')}</div>
            <Input
              value={pwdObj.oldPassword}
              status={pwdObjError.oldPassword ? 'error' : ''}
              onChange={(e) => { this.changePwd(e.target.value, 'oldPassword') }}
              type='password'
            />
            {pwdObjError.oldPassword && <div className='ul_form_info'>{t('users.Please input the oldPassword!')}</div>}
            <div className='ul_form_title'>{t('users.password')}</div>
            <Input
              value={pwdObj.password}
              status={pwdObjError.password ? 'error' : ''}
              onChange={(e) => { this.changePwd(e.target.value, 'password') }}
              type='password'
            />
            {pwdObjError.password && <div className='ul_form_info'>{t('users.Please input the password!')}</div>}
            <div className='ul_form_title'>{t('users.confirmPassword')}</div>
            <Input
              value={pwdObj.confirmPassword}
              status={pwdObjError.confirmPassword ? 'error' : ''}
              onChange={(e) => { this.changePwd(e.target.value, 'confirmPassword') }}
              type='password'
            />
            {pwdObjError.confirmPassword && <div className='ul_form_info'>{t('users.Please input the confirmPassword!')}</div>}
            {pwdObjError.confirmPasswordDiff && <div className='ul_form_info'>{t('users.The two passwords that you entered do not match!')}</div>}
          </div>
        </Modal>
      </div>
    )
  }
}

export default HeaderBar